// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RoutesConfig from "./Routes";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {RoutesConfig.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
