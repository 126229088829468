import React from 'react'
import Header from './shearOne/header'
import Footer from './shearOne/footer'

const FAQ = () => {
    return (
        <>
            <Header />
            <div id="sidebox-overlay"></div>

            <section class="page-header">
                <div class="page-header-shape"></div>
                <div class="container">
                    <div class="page-header-info">
                        <h4>Help &amp; Faqs!</h4>
                        <h2>
                            Frequently Asked <br />
                            <span>Questions!</span>
                        </h2>
                        <p>Everything your transport needs is already here!</p>
                    </div>
                </div>
            </section>


            <section class="blog-section blog-page bg-grey padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 sm-padding">
                            <div class="accordion faq-accordion" id="faq-accordion">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            What taxi services do Cab24 offer?

                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                Cab24 has many ways to get a taxi from the ranks in town centre, train station and bus station, also we have 5 main Cab24 taxi firms which are Cab24 Cabs & Airport Taxi Happicabs Fareway Taxi & Cab24 Taxi Cab24 Taxi Cab Chelms Ford Taxi


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Does Cab24 have stansted airport taxi?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                Yes we can take you to stansted airport from Cab24 prices starting from £50 and we do all the necessary flight tracking for a smooth and easy journey for you.


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Does Cab24 Cabs & Airport Taxi pick up and drop off to Cab24 City Racecourse?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                Yes we do all pick ups and drop offs to and from Cab24 City Racecourse.


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            What taxi services are available in Cab24?

                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                Cab24 offers various taxi options, including ranks in the town center, train station, and bus station, along with five main taxi firms.


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFive">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            What payment methods does Cab24 Cabs & Airport Taxi accept?

                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                We accept both card and cash payments for your convenience. Feel free to ask your driver for more details or inquire when booking.


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading6">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                            Do you offer transportation to and from Cab24 City Racecourse?

                                        </button>
                                    </h2>
                                    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                Yes, we provide pick-up and drop-off services to and from Cab24 City Racecourse.


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading7">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                            How can I book a taxi service in Cab24?

                                        </button>
                                    </h2>
                                    <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                To book a taxi, you can contact one of the main taxi firms directly or use their online booking platforms.


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading8">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                            What sets Cab24 Cabs & Airport Taxi apart from other services?

                                        </button>
                                    </h2>
                                    <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading8"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                Our service stands out for offering convenient payment methods, flight tracking for airport transfers, and transportation to popular venues like Cab24 City Racecourse.



                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading9">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                            Can I get a taxi from Cab24 train station to Stansted Airport?

                                        </button>
                                    </h2>
                                    <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                Can I get a taxi from Cab24 train station to Stansted Airport?

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading10">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                            Are there any special discounts or packages available for wedding transportation?

                                        </button>
                                    </h2>
                                    <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="heading10"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                Some taxi firms might offer special wedding transportation packages or discounts, so it's a good idea to inquire about these options.


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading11">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                            Is advance booking required for transportation to Stansted Airport?

                                        </button>
                                    </h2>
                                    <div id="collapse11" class="accordion-collapse collapse" aria-labelledby="heading11"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                                While advance booking is recommended, we also accommodate last-minute requests for taxi services to Stansted Airport. It's always best to book ahead to secure your preferred timing.


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading12">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                            Best hotels in Cab24?

                                        </button>
                                    </h2>
                                    <div id="collapse12" class="accordion-collapse collapse" aria-labelledby="heading12"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                        <p>Here we have the top ten best hotels in Cab24. 
                                                <br/>

                                                    1. County Hotel Cab24 
                                                    <br/>
                                                    2. Best Western Ivy Hill Hotel 
                                                    <br/>
                                                    3. Down Hall Hotel &amp; Spa 
                                                    <br/>
                                                    4. Pontlands Hotel 
                                                    <br/>
                                                    5. Greenwood Spa &amp; Hotel 
                                                    <br/>
                                                    6. Five Lakes Hotel 
                                                    <br/>
                                                    7. Channels Hotel 
                                                    <br/>
                                                    8. Lion Inn 4
                                                    <br/>
                                                    9. Essex Stays at The Kings Head 
                                                    <br/>
                                                    10. De Rougemont Manor 
                                                    </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading13">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                          Are there any unique features or specialties offered by Happicabs among Cab24's taxi services?

                                        </button>
                                    </h2>
                                    <div id="collapse13" class="accordion-collapse collapse" aria-labelledby="heading13"
                                        data-bs-parent="#faq-accordion">
                                        <div class="accordion-body">
                                            <p>
                                            Happicabs, a prominent taxi service in Cab24, distinguishes itself by providing a modern and reliable transportation experience. With its user-friendly online booking platform
                                            </p>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default FAQ
