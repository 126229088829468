import React from 'react'
import Header from './shearOne/header'
import Footer from './shearOne/footer'

const Services = () => {
    return (
        <>
            <Header/>
            <div id="sidebox-overlay"></div>

            <section class="page-header">
                <div class="page-header-shape"></div>
                <div class="container">
                    <div class="page-header-info">
                        <h4>Our Services!</h4>
                        <h2>Welcome to <span>Cab24</span> Services!</h2>
                        <p>Welcome to Cab24 Services! We are your dedicated partner for all transportation needs.</p>
                    </div>
                </div>
            </section>
            <section class="service-section bg-grey padding">
        <div class="bg-half"></div>
        <div class="container">
          <div class="section-heading text-center mb-40 wow fade-in-bottom" data-wow-delay="200ms">
            <h4 class=" text-trigo-yellow"><span class="bg-trigo-yellow"></span>What We Offer</h4>
            <h2 class="white">Begin your journey with Cab24 - Pioneering<br /> Transportation Excellence!</h2>
            <p>
              We successfully cope with tasks of varying complexity, provide
              long-term <br />guarantees and regularly master new technologies.
            </p>
          </div>

          <div class="swiper-outside">
            <div class="service-carousel">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="service-item wow fade-in-bottom" data-wow-delay="200ms">
                    <div class="service-thumb">
                      <img src="assets/img/service1.jpg" alt="img" />
                      <div class="service-shape-wrap">
                        <div class="service-shape"></div>
                      </div>
                      <div class="service-car">
                        <img src="assets/img/car-1.png" alt="car" />
                      </div>
                    </div>
                    <div class="service-content">
                      <h3>
                        <a href='bookride'>Sea Port Transport</a>
                      </h3>
                      <p>
                        The Cheapest Airport Transfer
                        Prices in Chelmsford
                        At Cab City, we pride ourselves in being
                        able to deliver the most punctual and reliable airport transfer service in Chelmsford at the most competitive prices around.PICK-UPS from Gatwick, Luton and Heathrow will have an additional charge based around local demand for taxis and any additional parking charges. <br /> <br />
                      </p>
                      <a class="read-more" href="bookride">Read More</a>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="service-item wow fade-in-bottom" data-wow-delay="400ms">
                    <div class="service-thumb">
                      <img src="assets/img/service2.jpg" alt="img" />
                      <div class="service-shape-wrap">
                        <div class="service-shape"></div>
                      </div>
                      <div class="service-car">
                        <img src="assets/img/car-1.png" alt="car" />
                      </div>
                    </div>
                    <div class="service-content">
                      <h3>
                        <a href="bookride">Airport Transport</a>
                      </h3>
                      <p>
                        Airport Collection & Hotel Transfer Services

                        We will make all your airport and hotel transfers for you. Further, we offer a comprehensive Meet & Greet services which includes the following:

                        We will check on your incoming flight to avoid an waiting time charges
                        We will meet you in the Arrivals Hall, usually outside Costa Coffee to avoid the crowded meeting area
                        Help you with your luggage
                        Drive you professionally
                        On the Day

                      </p>
                      <a class="read-more" href="bookride">Read More</a>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="service-item wow fade-in-bottom" data-wow-delay="600ms">
                    <div class="service-thumb">
                      <img src="assets/img/service3.jpg" alt="img" />
                      <div class="service-shape-wrap">
                        <div class="service-shape"></div>
                      </div>
                      <div class="service-car">
                        <img src="assets/img/car-1.png" alt="car" />
                      </div>
                    </div>
                    <div class="service-content">
                      <h3><a href="bookride">Train Station</a></h3>
                      <p>
                        Chelmsford train station Executive Transfer
                        If you are looking for a Southend airport luxury airport transfer or Southend airport minibus taxis then do not hesitate to contact us today. We offer full executive service to any all UK airports from any destination, our prices are extremely competitive so why not lounge in a luxury vehicle for near the same price as a cab. <br /> <br /> <br />
                      </p>
                      <a class="read-more" href="bookride">Read More</a>
                    </div>
                  </div>
                </div>
                {/* <div class="swiper-slide">
              <div class="service-item">
                <div class="service-thumb">
                  <img src="assets/img/ab%20(3).jpg" alt="img" />
                  <div class="service-shape-wrap">
                    <div class="service-shape"></div>
                  </div>
                  <div class="service-car">
                    <img src="assets/img/car-6.png" alt="car" />
                  </div>
                </div>
                <div class="service-content">
                  <h3><a href="service-details">Logistics</a></h3>
                  <p>
                    Everything your Cab business needs is already here!
                    Transporter India made for Cab service companies!
                  </p>
                  <a class="read-more" href="service-details">Read More</a>
                </div>
              </div>
            </div>  */}
              </div>
              <div class="swiper-pagination"></div>

            </div>

            <div class="swiper-nav swiper-next">
              <i class="las la-long-arrow-alt-right"></i>
            </div>
            <div class="swiper-nav swiper-prev">
              <i class="las la-long-arrow-alt-left"></i>
            </div>
          </div>
        </div>
      </section>
            <section class="cta-section-2 padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                            <div class="cta-content">
                                <h4>Cab24 Services!</h4>
                                <h2>Feel your journey with Cab24!</h2>
                                <a href="bookride" class="default-btn">Book a Taxi</a>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <ul class="check-list">
                                <li><i class="las la-arrow-right"></i>Easy payment systems.</li>
                                <li><i class="las la-arrow-right"></i>Make safety a top priority.</li>
                                <li><i class="las la-arrow-right"></i>First and quick pickups.</li>
                                <li><i class="las la-arrow-right"></i>Access Trigo globally.</li>
                            </ul>
                        </div>
                        <div class="col-lg-3">
                            <div class="cta-men"></div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section class="blog-section bg-grey padding">
                <div class="container">
                    <div class="section-heading text-center mb-40">
                        <h4><span></span>Recent Posts!</h4>
                        <h2>News And Insights!</h2>
                        <p>We successfully cope with tasks of varying complexity, provide long-term <br />guarantees and regularly master new technologies.</p>
                    </div>

                    <div class="row grid-post">
                        <div class="col-lg-4 col-md-6 padding-15">
                            <div class="post-card">
                                <div class="post-thumb">
                                    <img src="assets/img/post-2.jpg" alt="post" />
                                    <a href="blog-details.html" class="post-category">Business</a>
                                </div>
                                <div class="post-content-wrap">
                                    <ul class="post-meta">
                                        <li><i class="las la-calendar"></i>Jan 01 2022</li>
                                        <li><i class="las la-user"></i>Elliot Alderson</li>
                                    </ul>
                                    <div class="post-content">
                                        <h3>
                                            <a href="blog-details.html" class="hover">How to start initiating an startup in few days.</a>
                                        </h3>
                                        <p>Financial experts support or help you to to find out which way you can raise your funds more...</p>
                                        <a href="blog-details.html" class="read-more">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 padding-15">
                            <div class="post-card">
                                <div class="post-thumb">
                                    <img src="assets/img/post-2.jpg" alt="post" />
                                    <a href="blog-details.html" class="post-category">Startup</a>
                                </div>
                                <div class="post-content-wrap">
                                    <ul class="post-meta">
                                        <li><i class="las la-calendar"></i>Jan 01 2022</li>
                                        <li><i class="las la-user"></i>Elliot Alderson</li>
                                    </ul>
                                    <div class="post-content">
                                        <h3>
                                            <a href="blog-details.html">Financial experts support help you to find out.</a>
                                        </h3>
                                        <p>Financial experts support or help you to to find out which way you can raise your funds more...</p>
                                        <a href="blog-details.html" class="read-more">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 padding-15">
                            <div class="post-card">
                                <div class="post-thumb">
                                    <img src="assets/img/post-3.jpg" alt="post" />
                                    <a href="blog-details.html" class="post-category">Finance</a>
                                </div>
                                <div class="post-content-wrap">
                                    <ul class="post-meta">
                                        <li><i class="las la-calendar"></i>Jan 01 2022</li>
                                        <li><i class="las la-user"></i>Elliot Alderson</li>
                                    </ul>
                                    <div class="post-content">
                                        <h3>
                                            <a href="blog-details.html">Innovative business all over the world.</a>
                                        </h3>
                                        <p>Financial experts support or help you to to find out which way you can raise your funds more...</p>
                                        <a href="blog-details.html" class="read-more">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer />
        </>
    )
}

export default Services
