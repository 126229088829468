import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpenNav, setIsOpenNav] = useState(true);
  const toggleNav = () => { setIsOpenNav(!isOpenNav) };
  return (
  <>

    <header className="main-header">
      <div className="top-header">
        <div className="container">
          <div className="top-header-wrap">
            <div className="top-left">
              <p>Don't chase, Just Choose</p>
            </div>
            <div className="top-right">
              <ul className="top-header-nav">
                <li><a href="#">Help</a></li>
                <li><a href="#">Support</a></li>
                <li><a href="#">FAQ</a></li>
              </ul>
              <ul className="header-social-share">
                <li>
                  <a href="facebook.com"><i className="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="twitter.com"><i className="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="instagram.com"><i className="fab fa-instagram"></i></a>
                </li>
                <li>
                  <a href="linkedin.com"><i className="fab fa-linkedin"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mid-header">
        <div className="container">
          <div className="mid-header-wrap">
            <div className="site-logo">
            <Link to="/">  <img src="assets/img/cabdark.png" alt="Cab24" /></Link>
            </div>
   
            <ul className="header-info">
              <li>
                <div className="header-info-icon">
                  <i className="las la-envelope-open"></i>
                </div>
                <div className="header-info-text">
                  <h3><span>Email now</span>cab.24uk@hotmail.com</h3>
                  <h1 style={{ display: "none" }} title="Best digital marketing agency noida || best app devlopment company noida || best website devlopment company noida">
                    Codfty Pvt. Ltd
                  </h1>
                  <a href="https://codfty.com/" style={{ display: "none" }} title="Best digital marketing agency noida || best app devlopment company noida || best website devlopment company noida">
                  Codfty Pvt. Ltd
                  </a>
                </div>
              </li>
              <li>
                <div className="header-info-icon">
                  <i className="las la-map-marked-alt"></i>
                </div>
                <div className="header-info-text">
                  <h3><span>48 Rookes Crescent </span>Chelmsford, Essex CM1 3GF UK </h3>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
     
      <div className="nav-menu-wrapper">
        <div className="container">
          <div className="nav-menu-inner">
            <div className="site-logo">
            <Link to="/">  <img src="assets/img/cabdark.png" alt="Cab24" /></Link>
            </div>
   
            <div className={`header-menu-wrap ${isOpenNav ? " " : "d-block "}`}>
              <ul className="nav-menu">
                <li className="active dropdown_menu">
                <Link to="/"> Home </Link>
                </li>
                <li className="dropdown_menu">
                <Link to="/services">Services</Link>
                  {/* <ul>
                    <li><Link to="/services"> Our Services</Link></li>
                    <li><a href="#">Services Details</a></li>
                  </ul> */}
                </li>
                <li className="dropdown_menu">
               <Link to="/prices"> Prices</Link>
                 
                </li>
                    <li><Link to="/faq"> FAQ</Link></li>
                    <li><Link to="/about">About Us</Link> </li>

                {/* <li className="dropdown_menu">
                  <a href="#">About Us</a>
                  <ul>
                    <li><Link to="/about">About Us</Link> </li>
                    <li><a href="#">About Company</a></li>
                    <li><Link to="/bookride"> Book a Ride </Link></li>
                  </ul> 
                </li> */}
                {/* <li className="dropdown_menu">
                  <a href="#">Pages</a>
                  <ul>
                    <li><a href="#">Our Drivers</a></li>
                    <li><a href="#">Driver Details</a></li>
                    <li><a href="#">Customer Reviews</a></li>
                    <li><a href="#">Help &amp; Faq's</a></li>
                    <li><a href="#">404 Error</a></li>
                  </ul>
                </li>  */}
                    <li><Link to="/bookride"> Book a Ride </Link></li>

                <li><Link to="/contact">Contact</Link> </li>
              </ul>
            </div>
            <div className="menu-right-item">
              <div className="search-icon dl-search-icon">
                <i className="las la-search"></i>
              </div>
              <div className="sidebox-icon dl-sidebox-icon">
                <i className="las la-bars"></i>
              </div>
              <a href='contact' className="menu-btn">Contact Now</a>
            </div>
            <div className="mobile-menu-icon">
              <div className={`burger-menu ${isOpenNav ? "" : "menu-open"}`} onClick={toggleNav} >
                <div className="line-menu line-half first-line"></div>
                <div className="line-menu"></div>
                <div className="line-menu line-half last-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div id="popup-search-box">
    <div class="box-inner-wrap d-flex align-items-center">
      <form id="form" action="#" method="get" role="search">
        <input id="popup-search" type="text" name="s" placeholder="Type keywords here..." />
        <button id="popup-search-button" type="submit" name="submit">
          <i class="las la-search"></i>
        </button>
      </form>
      <div class="search-close"><i class="las la-times"></i></div>
    </div>
  </div>

  <div id="searchbox-overlay"></div>

  <div id="popup-sidebox" class="popup-sidebox">
    <div class="sidebox-content">
      <div class="site-logo">
        <a><img src="assets/img/logolight.png" alt="logo"/></a>
      </div>
      <p>Everything your Cab business needs is already here! Transporter India, a theme
        made for Cab service companies.</p>
      <ul class="sidebox-list">
        <li class="mb-0">
          <h3 class="text-warning">Registered Addresss</h3>
        </li>
        <li>
          <span>You can find us at:</span>48 Rookes Crescent Chelmsford,<br/> Essex CM1 3GF , UK 
        </li>
        <li class="mb-0">
          <h3 class="text-warning">Office Addresss</h3>
        </li>
        <li>
          <span>You can find us at:</span>48 Rookes Crescent Chelmsford,<br/> Essex CM1 3GF, UK 
        </li>

        <li><span>Email now:</span>cab.24uk@hotmail.com</li>
      </ul>
    </div>
  </div>
  
  <div id="sidebox-overlay"></div>
  </>
  )
}

export default Header;
