import React, { useState } from 'react'
import Header from './shearOne/header'
import Footer from './shearOne/footer'

const Contact = () => {

    const [successMessageVisible, setSuccessMessageVisible] = useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("access_key", "c7cac479-e1f0-4da6-8446-b9e18701e741");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());

        if (res.success) {
            console.log("Success", res);
            // Show success message
            setSuccessMessageVisible(true);
            // Clear the form
            event.target.reset();
        }
    };

    return (
        <>
            <Header />
            <div class="map-wrapper pt-90">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19804024.200887017!2d-25.28712256000061!3d52.70541921574815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8ebcc926584bb%3A0xee8d49019c55fc75!2s48%20Rookes%20Cres%2C%20Chelmsford%20CM1%203GF%2C%20UK!5e0!3m2!1sen!2sin!4v1709471686351!5m2!1sen!2sin" width="100%" height="400" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
            </div>

            <section class="contact-section bd-bottom padding">
                <div class="map"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="contact-details-wrap">
                                <div class="contact-title">
                                    <h2>Have Any <span>Questions?</span></h2>
                                    <p>Thank you for your interest in Cab24! We value your feedback, inquiries, and
                                        suggestions. Please feel free to reach out to us using the contact information provided
                                        below:</p>
                                </div>
                                <ul class="contact-details">
                                    <li class="mb-0"><h3>Registered Addresss</h3></li>
                                    <li><i class="fas fa-map-marker-alt text-light"></i>48 Rookes Crescent Chelmsford,<br />
                                        Essex CM1 3GF ,UK </li>
                                    <li class="mb-0"><h3>Office Addresss</h3></li>
                                    <li><i class="fas fa-map-marker-alt text-light"></i>48 Rookes Crescent Chelmsford,<br />
                                        Essex CM1 3GF , UK </li>
                                    <li><i class="fas fa-envelope text-light"></i>cab24uk@hotmail.com</li>
                                    <li><i class="fas fa-phone text-light"></i>01245526433</li>
                                    <li><i class="fas fa-phone text-light"></i>07305362004</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="contact-form">
                                <form onSubmit={onSubmit} class="form-horizontal">
                                    <div class="contact-title">
                                        <h2>Contact With Us! <span></span></h2>
                                    </div>
                                    <div class="contact-form-group">
                                        <div class="form-field">
                                            <input type="text" id="firstname" name="firstname" class="form-control"
                                                placeholder="First Name" required />
                                        </div>
                                        <div class="form-field">
                                            <input type="text" id="lastname" name="lastname" class="form-control"
                                                placeholder="Last Name" required />
                                        </div>
                                        <div class="form-field">
                                            <input type="email" id="email" name="email" class="form-control" placeholder="Email"
                                                required />
                                        </div>
                                        <div class="form-field">
                                            <input type="text" id="phone" name="phone" class="form-control"
                                                placeholder="Phone Number" required />
                                        </div>
                                        <div class="form-field message">
                                            <textarea id="message" name="message" cols="30" rows="4" class="form-control"
                                                placeholder="Message" required></textarea>
                                        </div>
                                        <div class="form-field">
                                            <button id="submit" class="default-btn" type="submit">Send Massage</button>
                                        </div>
                                    </div>
                                    <div id="successMsg" class="alert" role="alert"></div>
                                </form>
                                {successMessageVisible && (
                                    <div style={{ color: 'green', fontWeight: 'bold' }}>Success! Form submitted successfully.</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Contact
