import React from 'react'
import Header from './shearOne/header'
import Footer from './shearOne/footer'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
   <>
   <Header/>
   <section class="page-header error">
      <div class="container">
        <div class="page-header-info text-center mt-40">
          <h4>Oops! Where are we?</h4>
          <h2>404 Page <span>Not Found!</span></h2>
          <p>
            Page not Found! The page you are looking for was moved,
            <br />removed, renamed or might never existed.
          </p>
          <Link to="/"  class="default-btn mt-30"> Back To Homepage </Link>
        </div>
      </div>
    </section>
   <Footer/>
   </>
  )
}

export default Error404
