import React from 'react'
import Header from './shearOne/header'
import Footer from './shearOne/footer'

const Aboutus = () => {
    return (
        <>
            <Header />
            <section class="page-header">
                <div class="page-header-shape"></div>
                <div class="container">
                    <div class="page-header-info">
                        <h4>About Us!</h4>
                        <h2>
                            Feel your journey<br />
                            with <span> Cab24!</span>
                        </h2>
                        <p>
                            Welcome to Cab24, your trusted travel companion. We are a leading
                            transportation service provider committed to offering convenient and
                            reliable solutions to meet your travel needs.
                        </p>
                    </div>
                </div>
            </section>
            <section class="about-section padding">
                <div class="container">
                    <col-12>
                        <h2 class="text-start">Begin Your Journey with CAB24, Your Reliable Ride Partner!
                        </h2>
                        <p class="text-start mt-5">At CAB-24, we're more than just a cab service. As a leading logistics company, we
                            provide top-tier delivery services, ensuring the secure transportation of goods with transparency and
                            efficiency. We're also your go-to packers & movers in Gurgaon, offering a seamless transition to your new
                            home. Experience convenience, reliability, and transparency with CAB24, your trusted partner in rides,
                            logistics, and packers & movers.
                        </p>
                    </col-12>
                </div>
            </section>
            <section class="about-section padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="section-heading mb-40 wow fade-in-right" data-wow-delay="200ms">
                                <h2><span>Our Vision</span></h2>
                                <h2></h2>
                                <p>
                                    At Cab24, our vision is to revolutionize the way people travel
                                    and transport their belongings. We strive to provide innovative
                                    and technology-driven solutions that enhance the overall travel
                                    experience, ensuring convenience, safety, and reliability for
                                    our customers.
                                </p>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="about-img">
                                <img class="about-img1 wow fade-in-left" data-wow-delay="200ms" src="assets/img/vision.png" alt="img" />
                                <img class="about-img2 wow fade-in-bottom" data-wow-delay="400ms" src="assets/img/vision2.png" alt="img" />
                                <figure class="round-text"><img src="assets/img/experience-text-round.png" alt="img" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="about-section padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="about-img">
                                <img class="about-img1 wow fade-in-left" data-wow-delay="200ms" src="assets/img/tech.png" alt="img" />
                                <img class="about-img2 wow fade-in-bottom" data-wow-delay="400ms" src="assets/img/tech%20(2).png" alt="img" />
                                <figure class="round-text"><img src="assets/img/experience-text-round.png" alt="img" /></figure>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="section-heading mb-40 wow fade-in-right" data-wow-delay="200ms">
                                <h2><span>Innovation and Technology</span></h2>
                                <h2></h2>
                                <p>
                                    Cab24 believes in harnessing the power of innovation and
                                    technology to enhance your travel experience. Our user-friendly
                                    mobile app and website allow you to conveniently book your cab,
                                    track your ride in real-time, and access a range of
                                    transportation services at your fingertips. We continuously
                                    strive to stay ahead of the curve by adopting the latest
                                    advancements in the industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="about-section padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="section-heading mb-40 wow fade-in-right" data-wow-delay="200ms">
                                <h2><span>Community and Sustainability</span></h2>
                                <h2></h2>
                                <p>
                                    We are committed to giving back to the community and operating
                                    in an environmentally responsible manner. Cab24 actively
                                    participates in initiatives that contribute to the betterment of
                                    society, and we prioritize sustainable practices in our
                                    operations to minimize our carbon footprint.
                                </p>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="about-img">
                                <img class="about-img1 wow fade-in-left" data-wow-delay="200ms" src="assets/img/comm%20(2).png" alt="img" />
                                <img class="about-img2 wow fade-in-bottom" data-wow-delay="400ms" src="assets/img/comm.png" alt="img" />
                                <figure class="round-text"><img src="assets/img/experience-text-round.png" alt="img" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="about-section padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="about-img">
                                <img class="about-img1 wow fade-in-left" data-wow-delay="200ms" src="assets/img/tech.png" alt="img" />
                                <img class="about-img2 wow fade-in-bottom" data-wow-delay="400ms" src="assets/img/family.png" alt="img" />
                                <figure class="round-text"><img src="assets/img/experience-text-round.png" alt="img" /></figure>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="section-heading mb-40 wow fade-in-right" data-wow-delay="200ms">
                                <h2><span>Join the Cab24 Family</span></h2>
                                <h2></h2>
                                <p>
                                    Cab24 welcomes you to join our ever-growing family of satisfied
                                    customers. Whether you're a business traveler, a family moving
                                    to a new home, or a company in need of efficient logistics
                                    solutions, we have the expertise and dedication to meet your
                                    unique requirements. Experience the Cab24 difference and let us
                                    be your trusted travel companion.<br />Contact us today to
                                    explore our range of services and embark on a journey of
                                    convenience, reliability, and comfort with Cab24.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="testimonial-section bg-grey">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="section-heading mb-10">
                                <h4><span></span>Clients Testimonial</h4>
                                <h2>Cab24 UK Passenger Reviews...</h2>
                                <p>
                                    We successfully cope with tasks of varying complexity, provide
                                    long-term guarantees and regularly master technologies.
                                </p>
                            </div>
                            <div class="swiper-outside testi-pagination">
                                <div class="testimonial-carousel">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide">
                                            <div class="testi-item">
                                                <div class="quote-icon">
                                                    <i class="las la-quote-right"></i>
                                                </div>
                                                <p>
                                                    "Reviews on Google At Cab24, we pride ourselves at being the most reliable, punctual and friendliest taxi comapny in Cab24 offering our service at the most competitive prices around. This is reflected in the online reviews we have received to date so far. We are always delighted to win any positive reviews however it would be constructive for us if we are notified of any potential shortcomings so that we can act accordingly to ensure our customers get the best service possible. Please feel free to leave a review on Google by clicking on the link below"
                                                </p>
                                                <div class="testi-author">
                                                    <div class="author-thumb">
                                                        <img src="assets/img/comment-2.png" alt="author" />
                                                    </div>
                                                    <div class="author-info">
                                                        <h3>Garima Solanki</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                        <div class="swiper-slide">
                                            <div class="testi-item">
                                                <div class="quote-icon">
                                                    <i class="las la-quote-right"></i>
                                                </div>
                                                <p>
                                                    I recently used the services of this movers & packers
                                                    company, and I couldn't be happier with the experience.
                                                    The team was incredibly efficient and handled all my
                                                    belongings with care. They packed everything securely
                                                    and ensured that nothing was damaged during the move. I
                                                    highly recommend their services for a stress-free and
                                                    smoothÂ relocation!"
                                                </p>
                                                <div class="testi-author">
                                                    <div class="author-thumb">
                                                        <img src="assets/img/comment-3.png" alt="author" />
                                                    </div>
                                                    <div class="author-info">
                                                        <h3>Deepak Singh</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="swiper-slide">
                                            <div class="testi-item">
                                                <div class="quote-icon">
                                                    <i class="las la-quote-right"></i>
                                                </div>
                                                <p>
                                                    "The logistics service provided by this company was
                                                    exceptional. They handled my shipments with precision,
                                                    ensuring timely delivery and careful handling. Their
                                                    attention to detail and professionalism exceeded my
                                                    expectations. I highly recommend their logistics
                                                    services for efficient and reliable
                                                    transportationÂ solutions."
                                                </p>
                                                <div class="testi-author">
                                                    <div class="author-thumb">
                                                        <img src="assets/img/comment-4.png" alt="author" />
                                                    </div>
                                                    <div class="author-info">
                                                        <h3>Somya Sharma</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="swiper-slide">
                                            <div class="testi-item">
                                                <div class="quote-icon">
                                                    <i class="las la-quote-right"></i>
                                                </div>
                                                <p>
                                                    Good to have transportation available in places and
                                                    times it is otherwise hard to find, but the app has
                                                    always been a bit difficult to use especially how it
                                                    works or doesnt with large font phone settings.
                                                </p>
                                                <div class="testi-author">
                                                    <div class="author-thumb">
                                                        <img src="assets/img/comment-1.png" alt="author" />
                                                    </div>
                                                    <div class="author-info">
                                                        <h3>Amit Patwal</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="feature-wrap">
                                <div class="section-heading mb-30 wow fade-in-right" data-wow-delay="200ms">
                                    <h4 class="white"><span></span>Why Choose Us!</h4>
                                    <h2 class="white">Why Ride with Transporter UK?</h2>
                                    <p class="white">
                                        We successfully cope with tasks of varying complexity, provide
                                        long-term guarantees and regularly master technologies.
                                    </p>
                                </div>

                                <ul class="Trigo-features">
                                    <li class="wow fade-in-right" data-wow-delay="300ms">
                                        <div class="feature-icon">
                                            <i class="las la-gem"></i>
                                        </div>
                                        <div class="feature-content">
                                            <h3>Safe Guarantee</h3>
                                            <p>
                                                We successfully cope with tasks of <br />
                                                varying complexity!
                                            </p>
                                        </div>
                                    </li>
                                    <li class="wow fade-in-right" data-wow-delay="400ms">
                                        <div class="feature-icon">
                                            <i class="las la-taxi"></i>
                                        </div>
                                        <div class="feature-content">
                                            <h3>Fast Pickups</h3>
                                            <p>
                                                We successfully cope with tasks of <br />
                                                varying complexity!
                                            </p>
                                        </div>
                                    </li>
                                    <li class="wow fade-in-right" data-wow-delay="500ms">
                                        <div class="feature-icon">
                                            <i class="las la-tachometer-alt"></i>
                                        </div>
                                        <div class="feature-content">
                                            <h3>Quick Ride</h3>
                                            <p>
                                                We successfully cope with tasks of <br />
                                                varying complexity!
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div class="container-fluid mb-5">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <img src="assets/img/Tri-GO%20(1).png" class="img-fluid wow zoomIn" />
                    </div>
                    <div class="col-12 col-md-6 py-4" style={{ backgroundColor: " #203459" }}>
                        <h2 class="text-center text-white wow fade-in-left">Download Now</h2>
                        <p class="text-center text-white wow fade-in-right">
                            Download the app now to get services.
                        </p>
                        <ul class="app-feature text-center">
                            <li class="text-center">
                                <i><img class="feature-icon wow bounceIn" src="assets/img/icon-navigator.svg" alt="icon" /></i>
                                <h3 class="text-white wow fade-in-bottom">
                                    Easy to Search <br />
                                    Transporter UK!
                                </h3>
                            </li>
                            <li class="text-center">
                                <i><img class="feature-icon wow bounceIn" src="assets/img/icon-clock.svg" alt="icon" /></i>
                                <h3 class="text-white wow fade-in-bottom">
                                    Quick Pickups To <br />
                                    Save Time!
                                </h3>
                            </li>
                            <li class="text-center">
                                <i><img class="feature-icon wow bounceIn" src="assets/img/icon-docs.svg" alt="icon" /></i>
                                <h3 class="text-white wow fade-in-bottom">
                                    Inclusive Rates To<br />
                                    Enjoy Ride!
                                </h3>
                            </li>
                        </ul>
                        <div class="download-btn mt-4">
                            <a href="https://play.google.com/" target="_blank"><img
                                src="assets/img/google-play.svg" alt="PlayStore" /></a>
                            <a href="https://apps.apple.com" target="_blank"><img
                                src="assets/img/app-store.svg" alt="AppStore" /></a>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />

        </>
    )
}

export default Aboutus
