import React from 'react'
import Header from './shearOne/header'
import Footer from './shearOne/footer'

const Prices = () => {
  return (
   <>
   <Header/>
   <div id="sidebox-overlay"></div>

<section class="page-header">
    <div class="page-header-shape"></div>
    <div class="container">
        <div class="page-header-info">
            <h4>Our Prices!</h4>
            <h2>Best <span>Prices</span> !</h2>
            <p>Welcome to Cab24 Services! We are your dedicated partner for all transportation needs.</p>
        </div>
    </div>
</section>
   <section class="our-taxi padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 sm-padding">
                    <div class="pricing-item">
                        {/* <div class="pricing-head-wrap">
                            <div class="pricing-car">
                                <img src="assets/img/volikwagen.png" alt="car"/>
                                <div class="price">$2.50/km</div>
                            </div>
                        </div> */}
                        <div class="pricing-head">
                            <h3><a href="tax..">SEAPORTS</a></h3>                
                            <span class="location">Parking Fees Included</span>

                        </div>
                        <ul class="pricing-list">
                            <li className='text-dark'>Dover: <span>From £95</span></li>
                            <li className='text-dark'>Harwich Docks: <span>From £60</span></li>
                            <li className='text-dark'>Harwich Docks: <span>From £60</span></li>
                            <li className='text-dark'>Southampton Docks: <span>From £155</span></li>
                            <li className='text-dark'>Portsmouth : <span>From £190</span></li>
                            <li><a href="bookride" class="default-btn">Book Taxi Now</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                    <div class="pricing-item">
                        {/* <div class="pricing-head-wrap">
                            <div class="pricing-car">
                                <img src="assets/img/seater-8.png" alt="car"/>
                                <div class="price">$3.50/km</div>
                            </div>
                        </div> */}
                        <div class="pricing-head">
                            <h3><a href="bookride"> JUST AIRPORT PRICE LIST</a></h3>
                            <span class="location">Parking Fees Included</span>
                        </div>
                        <ul class="pricing-list">
                            <li className='text-dark'>Stansted Airport: <span>From £60</span></li>
                            <li className='text-dark'>Gatwick Airport: <span>From £140</span></li>
                            <li className='text-dark'>Heathrow Airport: <span>From £140</span></li>
                            <li className='text-dark'>Luton Airport: <span>From £140</span></li>
                            <li><a href="bookride" class="default-btn">Book Taxi Now</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                    <div class="pricing-item">
                        {/* <div class="pricing-head-wrap">
                            <div class="pricing-car">
                                <img src="assets/img/tesla.png" alt="car"/>
                                <div class="price">$4.50/km</div>
                            </div>
                        </div> */}
                        <div class="pricing-head">
                            <h3><a href="bookride">TRAIN STATION</a></h3>
                            <span class="location">Parking Fees Included</span>
                        </div>
                        <ul class="pricing-list">
                            <li className='text-dark'>Ashford International: <span>From £85</span></li>
                            <li className='text-dark'>Ebbsfleet : <span>From £60</span></li>
                            <li className='text-dark'>Kings Cross Station: <span>From £90</span></li>
                            <li className='text-dark'>St Pancras International: <span>From £90</span></li>
                            <li ><a href="bookride" class="default-btn">Book Taxi Now</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
   </>
  )
}

export default Prices
