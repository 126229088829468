import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="footer-top-wrap bg-grey">
          <div className="container">
            <div className="footer-top">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="brand">
                    <a className="footer-logo" ><img src="assets/img/logolight.png" alt="Cab24" /></a>
                    <p>
                      We successfully cope with tasks of varying complexity,
                      provide long-term guarantees and regularly master new
                      technologies.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-call">
                    {/* <img src="assets/img/image.png" alt="whatsapp" width="45px"/> */}
                    <a aria-label="Chat on WhatsApp" href="https://wa.me/+447305362004">  <img alt="Chat on WhatsApp" width="45px" src="assets/img/image.png" />
                    </a >
                    <p>
                      <span>Book a Ride</span><a href="https://wa.me/+447305362004">Chat on WhatsApp</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-mid-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 sm-padding">
                <div className="footer-item footer-list">
                  <div className="widget-title">
                    <h3>CAB24 UK</h3>
                  </div>
                  <ul className="footer-links">
                    <li>
                      <p style={{ color: "#ccc", fontWeight: 400 }}>
                        Cab24, your trusted travel companion. We are a leading transportation service provider committed to
                        offering convenient and reliable solutions to meet your travel needs. Whether you're looking for a
                        comfortable ride, efficient services, or seamless logistics solutions, Cab24 is
                        here to make your journey smooth and hassle-free
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 sm-padding">
                <div className="footer-item footer-list">
                  <div className="widget-title">
                    <h3>Useful Links</h3>
                  </div>
                  <ul className="footer-links">
                    <li><a href="bookride">Cab Booking</a></li>
                    <li><a href="faqs">Help Center</a></li>
                    <li><a href="refund%26cancellation">Refund and Cancellation</a></li>
                    <li><a href="privacy-policy">Privacy and Policy</a></li>
                    <li><a href="terms%26condition">Terms & Conditions</a></li>
                    <li><a href="contact">Contact Us</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 sm-padding">
                <div className="footer-item">
                  <div className="widget-title">
                    <h3>Head Office</h3>
                  </div>
                  <ul className="footer-contact">
                    <li>
                      <span>Location:</span>48 Rookes Crescent Chelmsford, <br />Essex CM1 3GF UK
                    </li>
                    <li><span>Contact Us:</span> <br/> 01245526433 / <br/>
                      07305362004 <br/> cab.24uk@hotmail.com </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 sm-padding">
                <div className="footer-item subscribe-wrap">
                  <div className="widget-title">
                    <h3>Newsletter Signup</h3>
                  </div>
                  <form action="#" className="subscribe-form">
                    <input className="form-control" type="email" name="email" placeholder="Your Email" required="" />
                    <input type="hidden" name="action" value="mailchimpsubscribe" />
                    <button className="submit">Subscribe Now</button>
                    <div className="clearfix"></div>
                    <div id="subscribe-result">
                      <div className="subscription-success"></div>
                      <div className="subscription-error"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="running-taxi">
            <div className="taxi"></div>
            <div className="taxi-2"></div>
            <div className="taxi-3"></div>
            <div className="taxi-4"></div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <p>
              © <span id="currentYear"></span> Copyrights © 2024 All Rights Reserved, Crafted with precision by
             <a href="https://codfty.com/" target='blank ' style={{color:"white"}}> Codfty. </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer

