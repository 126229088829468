import Aboutus from "./page/Aboutus"
import BookRide from "./page/BookRide"
import Contact from "./page/Contact"
import Error404 from "./page/Error404"
import FAQ from "./page/FAQ"
import Prices from "./page/Prices"
import Services from "./page/Services"
import Home from "./page/home"

const Routes =[
  {
    path:"/",
    element:<Home/>
  },
  {
    path:"about",  
    element:<Aboutus/>
  },
  {
    path:"bookride",
    element:<BookRide/>
  },
  {
    path:"services",
    element:<Services/>
  },
  {
    path:"faq",
    element:<FAQ/>
  },
  {
    path:"contact",
    element:<Contact/>
  },
  {
    path:"prices",
    element:<Prices/>
  },
  {
    path:"*",
    element:<Error404/>
  }
]
export default Routes
