import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GoogleReviewSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768, // Tablet and below
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1024, // Tablet landscape and below
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    // Sample reviews data (you can fetch dynamically)
    const reviews = [
        {
            id: 1,
            name: "Ryan Hazelwood",
            rating: 5,
            comment: "The team at Embed Social is so easy to work with. They are very quick to respond to any special requests and fast to implement account changes. ",
        },
        {
            id: 2,
            name: "Noah Wood",
            rating: 5,
            comment: "I can't thank Cab24 enough for their excellent service. Their drivers are not just professionals but also courteous companions during the ride.",
        },
        {
            id: 3,
            name: "Oliver Queen",
            rating: 4,
            comment: "Cab24 has been my go-to taxi service for a while now. Their reliability and friendly drivers make each journey a pleasant experience.",
        },
        {
            id: 4,
            name: "Barry Allen",
            rating: 4,
            comment: "Use regularly for airports from Chelmsford, always on time, excellent service, constant communication. Definitely recommend!",
        },
        {
            id: 5,
            name: "M.I",
            rating: 5,
            comment: "Fantastic service. Driver was on time and very helpful with my shopping unloading. Finally a reliable taxi service in Chelmsford.",
        },
        {
            id: 6,
            name: "Rizby choudhury",
            rating: 5,
            comment: "Great service the taxi drivers were very friendly and the journey was incredibly quick and safe",
        },
        {
            id: 7,
            name: "rofi chowdhury",
            rating: 5,
            comment: "Awesome cab firm the customer service is very good, with very polite staff on the switchboard. The drivers are very polite and helpful.",
        },
        {
            id: 8,
            name: "Gulam Elahi Choudhury",
            rating: 5,
            comment: "Very good service, high quality and class cars that pick you up",
        },
    ];

    return (
        <section id="google-reviews">
            <div className="review-header">
                <img src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png" alt="Google Logo" className="google-logo" />
                <h2>Our Google Reviews</h2>
                <a href="https://www.google.com/localservices/profile?spp=Cg0vZy8xMXk0bjA0ejI1&scp=CgAaBUNhYjI0KgVDYWIyNA%3D%3D&q=Cab24#ts=3" target="_blank" rel="noopener noreferrer" className="write-review-button">
                    Leave a review
                </a>
            </div>
            <Slider {...settings}>
                {reviews.map((review) => (
                    <div key={review.id} className="review-box">
                        <div className="review-content">
                            <div className="review-rating">
                                {Array.from({ length: 5 }, (_, index) => {
                                    const starClass = index < review.rating ? "fas fa-star" : "far fa-star";
                                    return <i key={index} className={starClass}></i>;
                                })}
                            </div>
                            <div className="review-comment">
                                <p>{review.comment}</p>
                            </div>
                            <div className="review-author">
                                <strong>{review.name}</strong>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </section>
    );
};

export default GoogleReviewSlider;