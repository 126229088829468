
import { useState } from 'react';
import Footer from './shearOne/footer'
import Header from './shearOne/header'


const BookRide = () => {
  
    const [successMessageVisible, setSuccessMessageVisible] = useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("access_key", "c7cac479-e1f0-4da6-8446-b9e18701e741");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());

        if (res.success) {
            console.log("Success", res);
            // Show success message
            setSuccessMessageVisible(true);
            // Clear the form
            event.target.reset();
        }
    };

  
    return (
        <>
        <Header/>
            <div id="sidebox-overlay"></div>

            <section class="page-header">
                <div class="page-header-shape"></div>
                <div class="container">
                    <div class="page-header-info">
                        <h4>Get Your Cab!</h4>
                        <h2>Book Your <span>Ride</span></h2>
                        <p>Everything your taxi business <br />needs is already here! </p>
                    </div>
                </div>
            </section>


            <div class="taxi-booking bg-grey padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 offset-lg-1">
                            <form onSubmit={onSubmit}>
                                <div class="taxi-booking-form">
                                    <div class="form-field">
                                        <i class="las la-user-tie"></i>
                                        <input type="text" id="name" name="name" class="form-control"  placeholder="Your Name" required />
                                    </div>
                                    <div class="form-field">
                                        <i class="las la-envelope-open"></i>
                                        <input type="email" id="email" name="email" class="form-control"  placeholder="Email" required />
                                    </div>
                                     <div class="form-field">
                                        <i class="las la-phone"></i>
                                        <input type="number" id="phone" name="phone" class="form-control"  placeholder="phone" required />
                                    </div>
                                    <div class="form-field">
                                        <i class="las la-tags"></i>
                                        <select name="packagetype" id="packagetype"  class="niceSelect">
                                            <option value="economy">Airport</option>
                                            <option value="standard">Train Station</option>
                                            <option value="business">Seaport</option>
                                        </select>
                                    </div>
                                    <div class="form-field">
                                        <i class="las la-user-friends"></i>
                                        <select name="passengers" id="passengers"  class="niceSelect">
                                            <option value="1 Person">1 Person</option>
                                            <option value="2 Person  ">2 Person  </option>
                                            <option value="3 Person  ">3 Person  </option>
                                            <option value="4 Person ">4 Person </option>
                                            <option value="5 Person ">5 Person </option>
                                            <option value="6 Person  ">6 Person  </option>
                                            <option value="7 Person  ">7 Person </option>
                                            <option value="8 Person ">8 Person  </option>
                                        </select>
                                    </div>
                                    <div class="form-field">
                                        <i class="las la-map-marker"></i>
                                        <input type="text" id="totalsuitcase" name="totalsuitcase" class="form-control"  placeholder="Total Suitcase"  required />
                                    </div>
                                    <div class="form-field">
                                        <i class="las la-map-marker"></i>
                                        <input type="text" id="startdest" name="startdest" class="form-control"  placeholder="Start Destination"  required />
                                    </div>
                                    <div class="form-field">
                                        <i class="las la-map-marker"></i>
                                        <input type="text" id="enddest" name="enddest" class="form-control"  placeholder="End Destination"  required />
                                    </div>
                                    <div class="form-field">
                                        <i class="las la-calendar"></i>
                                        <input type="text" id="ridedate" name="ridedate" class="form-control date-picker"  placeholder="Select Date"  required />
                                    </div>
                                    <div class="form-field">
                                        <i class="las la-clock"></i>
                                        <input type="text" id="ridetime" name="ridetime" class="form-control time-picker"  placeholder="Select Time"  required />
                                    </div>
                                    <div class="form-field">
                                        <button id="submit" value="Send" class="default-btn" type="submit">Book Your Taxi</button>
                                    </div>
                                </div>
                                <div id="form-messages" class="alert" role="alert"></div>
                            </form>
                            {successMessageVisible && (
                <div style={{ color: 'green', fontWeight: 'bold' }}>Success! Form submitted successfully.</div>
            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </>
    )
}

export default BookRide
