import React from 'react'
import Header from './shearOne/header'
import Footer from './shearOne/footer'
import { Link } from 'react-router-dom'
import "./review.css"
import GoogleReviewSlider from './GoogleRrview'

const Home = () => {
  return (
    <>
      <Header />
      <div className="slider-section">
        <div className="main-slider">
          <div className="swiper-wrapper">    
            {/* Slide 1 */}
            <div className="swiper-slide">
              <div className="slider-img">
                <img src="assets/img/slider1-bg.jpg" alt="img" className="kenburns" style={{ animationDuration: "12000ms" }} />
              </div>
              <div className="car-holder">
                <img
                  src="assets/img/car-1.png"
                  alt="car"
                  data-animation="car-animation"
                  data-duration="1.5s"
                />
                <div className="shape">
                  <div
                    data-animation="slide-in-bottom"
                    data-duration="1.5s"
                    data-delay="0.3s"
                  ></div>
                  <div
                    data-animation="fade-in"
                    data-duration="1.5s"
                    data-delay="0.2s"
                  ></div>
                  <div
                    data-animation="slide-in-top"
                    data-duration="1.5s"
                    data-delay="0.5s"
                  ></div>
                </div>
              </div>
              {/* Slider Content */}
              <div className="slider-content-wrap d-flex align-items-center text-left">
                <div className="container">
                  <div className="slider-content">
                    <div className="slider-caption-bg-1">
                      <div className="slider-caption medium">
                        <div className="inner-layer" data-animation="fade-in-bottom" data-delay="0.3s">
                          Travel securely with us!
                        </div>
                      </div>
                      <div className="slider-caption big">
                        <div className="inner-layer" data-animation="fade-in-bottom" data-delay="0.5s">
                          Book your cab Now, <br />Ride in Style!
                        </div>
                      </div>
                      <div className="slider-caption small">
                        <div className="inner-layer" data-animation="fade-in-bottom" data-delay="0.7s" data-duration="1s">
                          Everything your Cab business needs is already here! <br />Transporter UK made for Cab service companies!
                        </div>
                      </div>
                      <div class="slider-btn">
                      <a href='bookride' class="default-btn" data-animation="fade-in-bottom" data-delay="0.9s" style={{ animationDelay: "0.9s" }}>Book Taxi Now</a>
                      <a href="tel:+01245526433" class="default-btn" data-animation="fade-in-bottom" data-delay="0.9s" style={{ animationDelay: "1s" }}>Book Now Via Call &nbsp;&nbsp;<i class="fas fa-phone text-light"></i></a>
                        <a aria-label="Chat on WhatsApp" href="https://wa.me/+447305362004" target='blank' class="default-btn bg-success" data-animation="fade-in-bottom" data-delay="0.9s" style={{ animationDelay: "0.9s" }}> Book Now Via WhatsApp</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Slide 2, Slide 3, Slide 4 ... */}
            {/* Similarly, add JSX for other slides */}
          </div>
          {/* Slider Controls */}
          <div className="slider-controls">
            <div className="slider-control slider-button-prev">
              <span><i className="las la-long-arrow-alt-left"></i></span>Prev
            </div>
            <div className="slider-pagination"></div>
            <div className="slider-control slider-button-next">
              Next<span><i className="las la-long-arrow-alt-right"></i></span>
            </div>
          </div>
        </div>
      </div>

      <section class="about-section padding">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="about-img">
                <img class="about-img1 wow fade-in-left" data-wow-delay="200ms" src="assets/img/about-1.png" alt="img" />
                <img class="about-img2 wow fade-in-bottom" data-wow-delay="400ms" src="assets/img/about-2.png" alt="img" />
                <figure class="round-text">
                  <img src="assets/img/experience-text-round.png" alt="img" />
                </figure>
              </div>
            </div>
            <div class="col-md-6">
              <div class="section-heading mb-40 wow fade-in-right" data-wow-delay="200ms">
                <h4><span></span>About Our Company</h4>
                <h2>
                  Feel the difference and Relaxation with Transporter UK
                  Company!
                </h2>
                <p>
                  The Premier Chelmsford Taxi Service Cab24  is the premier Chelmsford taxi service. We are the name you can trust when it comes to delivering a punctual and friendly taxi service in and around the Chelmsford area. Book a Cab24 with one of our friendly call centre guys and set off within minutes from now in a clean and modern fully licensed vehicle.   We have an extensive fleet of saloon cars and six seater vehicles. Whether you are taking a short trip into town, going for a night out or maybe you are looking for a reliable and comfortable airport transfer, Cab24 is there for all your Chelmsford taxi and minicab needs.
                </p>
              </div>

              <ul class="about-info wow fade-in-right" data-wow-delay="400ms">
                {/* <li>
              <img class="owner-thumb" src="assets/img/cta-men.png" alt="thumb" />
              <div class="owner">
                <h4>Founder</h4>
                <img class="signature" src="assets/img/signature.png" alt="signature"/>
                <h5><span>Adarsh</span></h5>
              </div>
            </li>
            <li>
              <img class="owner-thumb" src="assets/img/cta-men.png" alt="thumb" />
              <div class="owner">
                <h4>Co-Founder</h4>
                <img class="signature" src="assets/img/signature.png" alt="signature"/>
                <h5><span>Mohan</span></h5>
              </div>
            </li> */}
                <li>
                  {/* <h2><span>Call For Cab</span><a href="tel:5267214392">5267-214-392</a></h2> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      <section class="service-section bg-grey padding">
        <div class="bg-half"></div>
        <div class="container">
          <div class="section-heading text-center mb-40 wow fade-in-bottom" data-wow-delay="200ms">
            <h4 class=" text-trigo-yellow"><span class="bg-trigo-yellow"></span>What We Offer</h4>
            <h2 class="white">Begin your journey with Cab24 - Pioneering<br /> Transportation Excellence!</h2>
            <p>
              We successfully cope with tasks of varying complexity, provide
              long-term <br />guarantees and regularly master new technologies.
            </p>
          </div>

          <div class="swiper-outside">
            <div class="service-carousel">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="service-item wow fade-in-bottom" data-wow-delay="200ms">
                    <div class="service-thumb">
                      <img src="assets/img/service1.jpg" alt="img" />
                      <div class="service-shape-wrap">
                        <div class="service-shape"></div>
                      </div>
                      <div class="service-car">
                        <img src="assets/img/car-1.png" alt="car" />
                      </div>
                    </div>
                    <div class="service-content">
                      <h3>
                        <a href='bookride'>Airport Collection & Hotel Transfer Services</a>
                      </h3>
                      <p>
                        We will make all your airport and hotel transfers for you. Further, we offer a comprehensive Meet & Greet services which includes the following:
                        We are extremely proud of our electric vehicles, which are recognised to be one of the finest fleets in Essex. Our limousines offer comfortable, prestigious seating for up to four mourners in the S-Class saloons and seating for eight in out Mercedes Vito people carriers.
                      </p>
                      <a class="read-more" href="bookride">Book now</a>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="service-item wow fade-in-bottom" data-wow-delay="400ms">
                    <div class="service-thumb">
                      <img src="assets/img/service2.jpg" alt="img" />
                      <div class="service-shape-wrap">
                        <div class="service-shape"></div>
                      </div>
                      <div class="service-car">
                        <img src="assets/img/car-1.png" alt="car" />
                      </div>
                    </div>
                    <div class="service-content">
                      <h3>
                        <a href="bookride">Airport Transport</a>
                      </h3>
                      <p>
                        Airport Collection & Hotel Transfer Services

                        We will make all your airport and hotel transfers for you. Further, we offer a comprehensive Meet & Greet services which includes the following:

                        We will check on your incoming flight to avoid an waiting time charges
                        We will meet you in the Arrivals Hall, usually outside Costa Coffee to avoid the crowded meeting area
                        Help you with your luggage
                        Drive you professionally
                        On the Day

                      </p>
                      <a class="read-more" href="bookride">Book Now</a>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="service-item wow fade-in-bottom" data-wow-delay="600ms">
                    <div class="service-thumb">
                      <img src="assets/img/service3.jpg" alt="img" />
                      <div class="service-shape-wrap">
                        <div class="service-shape"></div>
                      </div>
                      <div class="service-car">
                        <img src="assets/img/car-1.png" alt="car" />
                      </div>
                    </div>
                    <div class="service-content">
                      <h3><a href="bookride">Train Station</a></h3>
                      <p>
                        Chelmsford train station Executive Transfer
                        If you are looking for a Southend airport luxury airport transfer or Southend airport minibus taxis then do not hesitate to contact us today. We offer full executive service to any all UK airports from any destination, our prices are extremely competitive so why not lounge in a luxury vehicle for near the same price as a cab. <br /> <br /> <br />
                      </p>
                      <a class="read-more" href="bookride">Book Now</a>
                    </div>
                  </div>
                </div>
                {/* <div class="swiper-slide">
              <div class="service-item">
                <div class="service-thumb">
                  <img src="assets/img/ab%20(3).jpg" alt="img" />
                  <div class="service-shape-wrap">
                    <div class="service-shape"></div>
                  </div>
                  <div class="service-car">
                    <img src="assets/img/car-6.png" alt="car" />
                  </div>
                </div>
                <div class="service-content">
                  <h3><a href="service-details">Logistics</a></h3>
                  <p>
                    Everything your Cab business needs is already here!
                    Transporter India made for Cab service companies!
                  </p>
                  <a class="read-more" href="service-details">Read More</a>
                </div>
              </div>
            </div>  */}
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="swiper-nav swiper-next">
              <i class="las la-long-arrow-alt-right"></i>
            </div>
            <div class="swiper-nav swiper-prev">
              <i class="las la-long-arrow-alt-left"></i>
            </div>
          </div>
        </div>
      </section>

      <section class="booking-section">
        <div class="container">
          <div class="row pos-relative padding">
            <div class="col-lg-4">
              <div class="booking-car wow fade-in-left" data-wow-delay="200ms"></div>
            </div>
            <div class="col-lg-8">
              <div class="booking-wrap">
                <div class="section-heading mb-30">
                  <h4 class="text-trigo-yellow"><span class="bg-trigo-yellow"></span>Online Booking</h4>
                  <h2 class="white mt-3">Book Your Cab Ride</h2>
                </div>
                <h2 class="text-white my-3">Begin your journey with Cab24 - Cab24 <br /> Transportation Excellence!</h2>
                <form action='bookride'>
                  <div class="booking-form">
                    <div class="form-field">
                      <i class="las la-user-tie"></i>
                      <input type="text" id="full-name" name="full-name" class="form-control" placeholder="Your Name" required="" />
                    </div>
                    <div class="form-field">
                      <i class="las la-envelope-open"></i>
                      <input type="email" id="email" name="email" class="form-control" placeholder="Email" required="" />
                    </div>
                    <div class="form-field">
                      <i class="las la-tags"></i>
                      <select name="package-type" id="type" class="niceSelect" style={{ display: "none" }} >
                        <option value="standard">Airport</option>
                        <option value="business">Seaport</option>
                        <option value="economy">Train Station</option>
                      </select><div class="nice-select niceSelect" tabindex="0"><span class="current">Airport</span><ul class="list"><li data-value="standard" class="option selected">Seaport</li><li data-value="business" class="option">Train Station</li><li data-value="economy" class="option">Airport</li></ul></div>
                    </div>
                    <div class="form-field">
                      <i class="las la-user-friends"></i>
                      <select name="passengers" id="passengers" class="niceSelect" style={{ display: "none" }}>
                        <option value="1">1 Person</option>
                        <option value="2">2 Person  with 2 suitcase</option>
                        <option value="3">3 Person  with 2 suitcase</option>
                        <option value="4">4 Person with 2 suitcase</option>
                        <option value="5">5 Person with 3 suitcase</option>
                        <option value="5">6 Person  with 4 suitcase</option>
                        <option value="5">7 Person  with 6 suitcase</option>
                        <option value="5">8 Person  with 6 suitcase</option>
                      </select><div class="nice-select niceSelect" tabindex="0"><span class="current">1 Person</span><ul class="list"><li data-value="1" class="option selected">1 Person</li><li data-value="2" class="option">2 Person</li><li data-value="3" class="option">3 Person</li><li data-value="4" class="option">4 Person</li><li data-value="5" class="option">5 Person</li></ul></div>
                    </div>
                    <div class="form-field">
                      <i class="las la-map-marker"></i>
                      <input type="text" id="start-dest" name="start-dest" class="form-control" placeholder="Start Destination" required="" />
                    </div>
                    <div class="form-field">
                      <i class="las la-map-marker"></i>
                      <input type="text" id="end-dest" name="end-dest" class="form-control" placeholder="End Destination" required="" />
                    </div>
                    <div class="form-field">
                      <i class="las la-calendar"></i>
                      <input type="text" id="ride-date" name="ride-date" class="form-control date-picker" placeholder="Select Date" required="" />
                    </div>
                    <div class="form-field">
                      <i class="las la-clock"></i>
                      <input type="text" id="ride-time" name="ride-time" class="form-control time-picker" placeholder="Select Time" required="" />
                    </div>
                    <div class="form-field">
                      <button href="bookride" id="submit" class="default-btn" type="submit">Book Your Taxi</button>
                    </div>
                  </div></form>
                <p class="text-white my-3">
                  We successfully cope with tasks of varying complexity, provide
                  long-term <br />guarantees and regularly master new technologies.
                </p>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pricing-section bg-grey bd-bottom padding">
        <div class="container">
          <div class="section-heading text-center mb-40 wow fade-in-bottom" data-wow-delay="200ms">
            <h4><span></span>Lets Go With Us!</h4>
            <h2>Choose Lowest Fare, Always!</h2>
            <p>Download Our App for the Ultimate Transportation Solution.</p>
          </div>

          <ul class="nav pricing-tab-menu" id="pricing-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="hybrid-taxi" data-bs-toggle="tab" data-bs-target="#hybrid-taxi-content"
                type="button" role="tab" aria-controls="hybrid-taxi-content" aria-selected="true">
                Teslla Premium Taxi
              </button>
            </li>
            {/* <li class="nav-item" role="presentation">
          <button class="nav-link" id="town-taxt" data-bs-toggle="tab" data-bs-target="#town-taxi-content" type="button"
            role="tab" aria-controls="town-taxi-content" aria-selected="false">
            Sedan Cab
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="limousine-taxi" data-bs-toggle="tab" data-bs-target="#limousine-taxi-content"
            type="button" role="tab" aria-controls="limousine-taxi-content" aria-selected="false">
            Suv/Muv Cab
          </button>
        </li> */}
          </ul>
          <div class="tab-content" id="pricing-tab-content">
            <div class="tab-pane fade show active" id="hybrid-taxi-content" role="tabpanel"
              aria-labelledby="hybrid-taxi-content">
              <div class="row">
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img src="/assets/img/teslacar.png" alt="car" />
                      </div>
                    </div>
                    <ul class="pricing-head pricing-list">
                      <li>
                        <Link to="/bookride" class="default-btn">Book Now</Link></li>
                    </ul>

                    <div class="post-card">
                      <div class="post-content-wrap">
                        <ul class="post-meta">
                          <li><i class="las la-user-friends"></i>3</li>
                          <li><i class="las la-suitcase-rolling"></i>2</li>
                          <li><i class="las la-shopping-bag"></i>2</li>
                        </ul>
                        <div class="post-content">
                          <h3>
                            <a class="hover">Electric Vehicle</a>
                          </h3>
                          <p>Eco Friendly Choice – professional driver and medium car for up to 3 people and 2 big luggages + 2 hand luggages</p>
                          <a class="read-more">From £65</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img src="assets/img/seater-8.png" alt="car" />
                      </div>
                    </div>
                    <ul class="pricing-head pricing-list">  <br />
                      <li> <Link to="/bookride" class="default-btn">Book Now</Link></li>
                    </ul>
                    <div class="post-card">
                      <div class="post-content-wrap">
                        <ul class="post-meta">
                          <li><i class="las la-user-friends"></i>8</li>
                          <li><i class="las la-suitcase-rolling"></i>8</li>
                          <li><i class="las la-shopping-bag"></i>8</li>
                        </ul>
                        <div class="post-content">
                          <h3>
                            <a class="hover">MPV8</a>
                          </h3>
                          <p>Everyday choice – professional driver and big car for up to 8 people and 8 big luggages + 8 hand luggages.</p>

                          <a class="read-more">From £90</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img src="assets/img/blackcar.png" alt="car" />
                      </div>
                    </div>
                    <ul class="pricing-head pricing-list">
                      <li> <Link to="/bookride" class="default-btn">Book Now</Link></li>
                    </ul>
                    <div class="post-card">
                      <div class="post-content-wrap">
                        <ul class="post-meta">
                          <li><i class="las la-user-friends"></i>4</li>
                          <li><i class="las la-suitcase-rolling"></i>2</li>
                          <li><i class="las la-shopping-bag"></i>2</li>
                        </ul>
                        <div class="post-content">
                          <h3>
                            <a class="hover"> Saloon</a>
                          </h3>
                          <p>Everyday choice – professional driver and medium car for up to 4 people and 2 big luggages + 2 hand luggages.</p>
                          <a class="read-more">From £50</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <GoogleReviewSlider/>
    <br/>
    <br/>
      {/* <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-12 col-md-6">
        <img src="assets/img/Tri-GO%20(1).png" class="img-fluid wow zoomIn" />
      </div>
      <div class="col-12 col-md-6 py-5" style={{backgroundColor:"#181817"}}>
        <div>
          <h2 class="text-center text-white wow fade-in-left">
            Download Now
          </h2>
          <p class="text-center text-white wow fade-in-right">
            Download the app now to get services.
          </p>
          <ul class="app-feature text-center">
            <li class="text-center">
              <i><img class="feature-icon wow bounceIn" src="assets/img/icon-navigator.svg" alt="icon" /></i>
              <h3 class="text-white wow fade-in-bottom">
                Easy to Search <br />
                Transporter India!
              </h3>
            </li>
            <li class="text-center">
              <i><img class="feature-icon wow bounceIn" src="assets/img/icon-clock.svg" alt="icon" /></i>
              <h3 class="text-white wow fade-in-bottom">
                Quick Pickups To <br />
                Save Time!
              </h3>
            </li>
            <li class="text-center">
              <i><img class="feature-icon wow bounceIn" src="assets/img/icon-docs.svg" alt="icon" /></i>
              <h3 class="text-white wow fade-in-bottom">
                Inclusive Rates To<br />
                Enjoy Ride!
              </h3>
            </li>
          </ul>
          <div class="download-btn mt-4">
            <a href="https://play.google.com/" target="_blank"><img
                src="assets/img/google-play.svg" alt="PlayStore" /></a>
            <a href="https://apps.apple.com" target="_blank"><img
                src="assets/img/app-store.svg" alt="AppStore" /></a>
          </div>
        </div>
      </div>
    </div>
  </div> */}
      <Footer />
    </>
  )
}

export default Home
